import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {},//用户信息
    applyStatus: '',//用户申请状态
    accountId: '',//account id
    //当前选择菜单
    activeMenu: '',
    faceCollect: false,
    faceVerify: false,
    tabName: 'home',
    caseList: [
      {
        title: '深圳市一咻科技有限公司',
        titleDesc: 'SCP·进出口供应链系统',
        info: '一咻通关立足人工智能（AI）、大数据、区块链等科技高地，融入“助力产业、释放快乐、科技兴邦”的人文关怀与报国情怀，围绕中小微及SOHO外贸企业的经营与订单交付，聚焦于电子产业，打造一个全模式、全流程、全场景的“进出口自助通关平台“。依托平台的赋能，您可轻松完成包括报关、报检、缴税、退税、付汇、结汇、仓储、物流、结算等一系列工作，甚至1-2个人就可以把一家公司运营得井井有条。',
        detailList: [
          {
            title: '重复性人工操作多',
            text: '进出口报关环节涉及关、检、汇、税、客户、供应商、服务商等多主体多组织，操作节点比较多，录单工作重复且巨大，每个节点都需要人力投入，增加人力成本。'
          },
          {
            title: '业务流系统化',
            text: '将线下业务流程经过梳理抽象，形成标准，放到系统上，业务链条再长也不担心。数据按照预设好的路线，一级一级往下传递；每个版块有专岗负责，用户只需按照系统指引操作即可，流程更标准，人工教育成本也更低。'
          },
          {
            title: '数据准确性要求高',
            text: '进出口报关业务，涉及向海关、税局等多个监管部门提交资料，数据准确性不容马虎，且过程中涉及的单证较多，内容较多，流转环节也多，极其容易出错。'
          },
          {
            title: '数据流标准化',
            text: '系统采用统一的标准数据格式、所有数据使用统一的数据接口、数据计算与自动化数据验证；对于用户的录入错误采用工单留痕机制修改，确保数据核算可追溯，并提供数据报警机制，能显著减少数据错误，提高流程效率和合规性。'
          },
          {
            title: '业务状态及时响应',
            text: '因为进出口报关业务链长，客户对过程中进度的把握需求就更加急切。常规做法是客户不断给客服人员留言发消息，咨询某某订单的进展，而客服需要向企业内反馈，得到回复后才能一级一级传递信息，信息反馈慢，非常影响客户体验。'
          },
          {
            title: '信息流平台化',
            text: '系统建立了信息流分发机制，通过客户端站内信、邮件、短信、公众号及时通知用户，使客户能够实时跟踪订单进展并在客户端+手机端操作互动。减少了信息传递的时间和不必要的沟通。提高了业务效率和客户满意度。'
          },
        ]
      },
      {
        title: '深圳市达迈科技信息有限公司',
        titleDesc: 'ERP·进销存产系统',
        info: '深圳市达迈科技信息有限公司和先进科技（香港）有限公司是专业的元器件代理商。总部位于深圳市南山区，在北京、上海、长沙皆设有分支机构，并在深圳和香港运营仓储过万平米。达迈科技自2016年，一直致力于为半导体产业链客户提供AI，光通讯，存储，功率等各类元器件的代理服务，及元器件选型、技术支持、进出口报关、仓储物流等一站式服务。专注深耕于IOT、通讯、消费、IDC四大市场方向。',
        detailList: [
          {
            title: '库存优化问题',
            text: '市场需求的不确定性和波动性使得准确预测困难，可能导致库存过高或过低。这不仅占用资金，还可能导致库房拥挤、积压和过期货物。'
          },
          {
            title: '数字化库存管理',
            text: '系统包含库存管理系统，具备数据分析和需求预测算法，可以监测库存水平、分析历史销售数据、考虑季节性变化和市场趋势，生成准确的需求预测。通过定期库存检查和库龄分析，能够最大程度地减少库存损失和陈旧库存的问题。'
          },
          {
            title: '供应链可见性不足',
            text: '代理分销业务需要与多个供应商、制造商和客户协同工作，确保供应链的顺畅运作；而传统的手工操作缺乏对供应链各环节的实时可见性，难以及时发现问题、响应变化，可能导致交付延误、客户满意度下降；'
          },
          {
            title: '采销链协同管控',
            text: '系统能够整合供应链数据，并提供实时监控和报告功能。通过物料代码追踪、库存分析和交付时间估算，可以随时了解物料流动、供销情况和订单状态。通过此类提升可视化的功能能更好地规划和协调供应链上下游活动，提前识别潜在问题。'
          },
          {
            title: '订单处理和客户服务',
            text: '代理公司需要处理大量订单，确保及时交货，同时提供卓越的客户服务，以满足客户需求；在进销存的各个环节中，处理多样化的订单和客户需求可能变得复杂，容易出错，影响客户服务质量和交货准时性'
          },
          {
            title: '提升订单处理效率',
            text: '系统将销售、采购、库存和财务数据集成在一个平台上，通过自动化策略的引入，从订单录入、库存检查、价格计算到交付调度上尽可能减少人为干预和错误。通过分析订单趋势和客户行为进而提供更多个性化的客户服务决策依据'
          },
        ]
      },
      {
        title: '易特达供应链（上海）有限公司',
        titleDesc: 'TMS·国际物流系统',
        info: '易特达供应链总部位于上海，在香港、深圳、义乌等地设有公司与分支机构，主要提供国际海运国际空运、多式联运等国际物流服务。我们拥有丰富的船公司与航空公司航线资源，覆盖欧洲、北美、南美、东南亚、非洲等全球主要区域，具有行业领先的信息化平台和数字化能力，能为客户提供优质和全程可视的服务，公司团队经验丰富，核心团队行业经验超过10年，立志为广大客户提供专业、安全、省心的服务。',
        detailList: [
          {
            title: '货物跟踪和可见性问题',
            text: '客户需要实时跟踪货物的位置和状态，但在国际运输中，货物可能经过多个运输环节，跟踪变得复杂，可见性不足'
          },
          {
            title: '物流追踪可视化',
            text: '系统的物流管理可提供实时货物跟踪和可见性。借用物联网传感器、GPS等技术实时监控货物，同时通过云计算和移动应用向客户提供可视化的货物追踪信息，以增强客户体验和透明度'
          },
          {
            title: '供应链复杂性和成本控制',
            text: '国际物流中存在多个运输模式、供应商和运输节点，使供应链变得复杂。成本控制和效率优化是挑战'
          },
          {
            title: '运价测算与最优路线',
            text: '系统可以规划和优化运输路线、模式和成本。通过第三方接口的运输数据，可以利用数据分析和优化算法，测算出最佳路线，在遇到特殊情况下还可以实现智能调度，进而提高货物运输的效率，降低成本'
          },
          {
            title: '风险管理和垫资问题',
            text: '国际运输涉及高额成本，客户可能要求延迟支付货款或垫资，从而增加了财务风险和资金压力'
          },
          {
            title: '贯穿始终的风控管理',
            text: '系统的风险管理通过信用评估工具和财务分析给予相应的授信额度。在下单、付款、发货的环节加入风控审批，确保风险得到控制，资金可及时回收，有助于降低财务风险并保持资金的流动性。'
          },
        ]
      },
      {
        title: '芯起国际物流云仓有限公司',
        titleDesc: 'WMS·精益仓储系统',
        info: '芯起国际物流云仓有限公司是专业的精益仓储+国际物流服务商。总部位于上海张江，并在长三角、珠三角、香港、新加坡、新疆、哈萨克斯坦等地具备自营仓库。芯起始终致力于半导体领域的国际物流及仓储业务，可为客户提供精益仓储、保税仓储，以及装卸、分拨、条码管理、存仓分析等多元化增值服务及关联的国际海运空运及多式联运服务。芯起的自营仓库具备先进仓储设备，拥有成熟的管理系统与专业的操作团队，可以做到3小时分拣、24小时配送。',
        detailList: [
          {
            title: '库存可见性和精确性问题',
            text: '管理金融仓、HUB仓和常规仓库存需要确保实时可见性，以及满足不同客户的多样性需求，包括快速交付和定制服务，并需要规避可能存在数据不一致、错误或滞留的问题'
          },
          {
            title: '库存数字化管理',
            text: '通过库存和订单管理跟踪库存和订单的实时状态。结合实时监控、状态变化和数据分析提升过程的可视化。使用需求预测和库存规划、预警等工具满足不同客户需求，优化库存和降低库存成本'
          },
          {
            title: '订单处理和流程复杂性',
            text: '金融仓和HUB仓业务可能涉及大量订单和复杂的流程，如分拣、集散、定制服务等，容易出现效率低下和错误'
          },
          {
            title: '订单策略管控',
            text: '通过订单管理系统，订单按各类策略要求执行自动化处理。系统自动拆分子母订单、分配库存、计算费用、生成打印标签和抓取数据形成报表，可以提高订单处理效率并降低错误率'
          },
          {
            title: '财务管理和费用控制',
            text: '金融仓业务需要管理多种金融产品，如贷款、风险管理等，而HUB仓和常规仓业务需要精确控制仓储费用，方便财务快速计算'
          },
          {
            title: '费用动态管控',
            text: '系统内置财务管理与成本分析功能，以管理财务流程、费用计算和利润分析。这些功能可以提供实时财务数据、费用追踪和预算控制，以确保财务可持续性和费用合理性'
          },
        ]
      },
      {
        title: '深圳正和跨境数字科技有限公司',
        titleDesc: 'ECS·电子商务系统',
        info: '正和数科以成为具有独特市场价值的跨境新品孵化中心，赋能更多中小跨境卖家为企业愿景，让跨境选品更胜一筹为企业使命。链接优质源头工厂，整合原创产品的设计和研发，只为中小跨境卖家打造有品调，有创意的中高端产品定制商城--正物和造。正和致力于为更多跨境人打通从新品设计、产品私模商业化、产品知识产权保护、商业品牌营销与综合跨境供应服务链。推动产业带数字化升级，让国造实现“正品出海、和风远航“。',
        detailList: [
          {
            title: '供应链管理和库存优化',
            text: '电商业务需要高效的物流和供应链体系，以确保货物按时、按要求送达客户手中，另外需要确保库存精确、有效地管理，避免过多的滞销库存或缺货问题'
          },
          {
            title: '供应链综合管理',
            text: '系统搭配了进销存供应链管理子系统，整合了物流、仓储、订单等子功能。这些可能可以监控货物的进销库存情况、跟踪交货进度，提高供应链可见性和效率。'
          },
          {
            title: '市场竞争和定价策略',
            text: '不管是国内电商还是跨境电商市场，竞争都非常激烈，需要制定有效的定价策略，同时跟踪竞争对手的价格和促销活动，另外电商公司需要提供出色的客户体验，包括个性化的产品推荐和购物建议，以增加销售和客户忠诚度'
          },
          {
            title: '定价与促销功能',
            text: '系统采用数据采集+数据分析功能来确定价格点和促销策略，并监测市场价格变化、评估竞争对手的定价策略，自动调整价格以保持竞争力。同时使用客户分析工具来了解购买者的行为和偏好，优化产品定价和促销策略并提供个性化产品推荐'
          },
          {
            title: '跨境支付和结算复杂性',
            text: '国内电商与跨境电商结合需要处理多种货币和支付方式，同时满足不同国家的法规和合规性要求，这增加了支付和结算的复杂性'
          },
          {
            title: '国内外线上支付',
            text: '系统已引入跨境支付处理平台，能够支持多种货币、支付方式和国际法规。对于国内则具备第三方支付+银企直联结算，同时支持2B和2C的结算，无需线下结算，能有效降低交易成本和提高支付速度'
          },
        ]
      },
      {
        title: '深圳市中芯供应链有限公司',
        titleDesc: 'SCF·供应链金融系统',
        info: '中芯供应链是始终专注于半导体产业的领先供应链综合服务商。公司成立于2011年，总部位于深圳前海深港合作区，在北京、上海、长沙、香港等地设立分子公司及办事处8个，拥有员工逾200人。近两年，公司进出口规模超过400亿元。公司以“服务中国芯、助力中国造”为使命，通过数字化产业服务平台的运营，以技术驱动资源整合，重点面向芯片设计/制造/分销、方案设计与产品集成等领域，为全产业链客户提供端到端的智能化、一体化供应链综合服务。',
        detailList: [
          {
            title: '资金流动性管理',
            text: '因为融资成本的问题，需要确保流动资金足够以支付给供应商，但同时要避免过多的资本占用'
          },
          {
            title: '资金管控与预测',
            text: '供应链金融系统提供授信管理、订单付款发货等风控节点管控，严格控制资金放出的风险，并通过贷中监控及时发现风险，对逾期进行严格管理，及时回笼资金，此外可通过财务预测和分析工具规划和管理未来的资金需求'
          },
          {
            title: '数据管理和可追溯',
            text: '进出口业务涉及大量数据，需要确保数据的准确性和可见性，以支持决策和合作伙伴之间的信息共享，企业和消费者对供应链的可持续性和产品可追溯性的关注增加，也需要满足这些要求'
          },
          {
            title: '数据综合管理',
            text: '系统具备数据管理功能，可确保数据的一致性和完整性，集成了各类报表统计分析以提高数据可见性和支持决策制定，所有数据可与联盟链打通支持全程追溯，系统采用的电子签也具备时间戳和CA存证功能'
          },
          {
            title: '业务链条风险点较多',
            text: '进出口公司面临客户信用风险、货币汇率波动、贸易法律法规等多种风险，尤其是垫资业务对风险管控具备非常高的要求'
          },
          {
            title: '风险的严格管控',
            text: '系统已建立从贷前到贷中贷后的一系列管控措施，授信银行可一起参与客户评分评级及额度设立，通过工商数据接口严格监控客户状态并定期走访，资金已严控到订单维度，在客户逾期时及时了解情况催促回款，必要时具备法务诉讼及强执'
          },
        ]
      },
      {
        title: '深圳市雪尔马特企业发展有限公司',
        titleDesc: 'SMS·门店经营系统',
        info: '雪尔马特SNOWMART是专注于滑雪领域的品牌连锁机构，以专业·贴心·乐享为理念，通过独创的由专业滑雪教练全程指导的室内滑雪教学课程，为都市人群提供滑雪全方位服务。10倍于雪场的学习效率，让更多人在繁华的大都市中安全、高效、专业地学会滑雪。解锁人生体验成就，全方面提高身体素质，开拓新鲜项目生活。实现社交感+体验感+沉浸感，打破气候、地域、年龄等限制，让室内滑雪成为大众休闲娱乐运动。',
        detailList: [
          {
            title: '课程和设施管理',
            text: '因客户的到店情况可能变化较大，管理多个门店的滑雪课程和设施可能会变得复杂，包括课程安排、教练调度和设备维护等'
          },
          {
            title: '门店教学动态管控',
            text: '系统引入了教练、课程和设施管理，可自动化课程排班、教练分配和设备维护计划。这些功能可以提供实时可见性，帮助优化资源利用并降低运营成本'
          },
          {
            title: '会员管理和客户体验',
            text: '对于每一个门店来说，维护会员信息、追踪会员付款和提供个性化的滑雪体验对于保持客户满意度至关重要'
          },
          {
            title: '注重客户服务',
            text: '系统采用会员管理和客户关系管理功能，可管理会员数据库、快速收付款及针对客户数据做分析以提供个性化建议。这些功能可以提高会员满意度，促进重复访问和口碑传播'
          },
          {
            title: '市场推广和销售',
            text: '室内滑雪业务需要有效的市场推广策略，以吸引新客户并提高销售，招聘的销售也需要借助工具来促进自己的业绩达成'
          },
          {
            title: '多渠道推广打通',
            text: '系统已和多类数字营销工具联合以扩大市场影响力。抖音号、视频号、头条、公众号、线下广告同步推进并在系统内同步记录，另外系统具备数据分析工具辅助销售了解客户行为和市场趋势，以优化市场推广和定价策略'
          },
        ]
      },
      {
        title: '某离岸信托有限公司',
        titleDesc: 'TFS·创新金融系统',
        info: '离岸信托公司简介;◆ 一家注册在香港，在新加坡、伦敦、纽约有分支机构的，综合性投资和企业服务公司;◆ 提供广泛的服务，包括投资咨询、公司架构、市场调研、财务咨询、法律法规合规以及投资后管理;◆ 拥有超过50家合作机构，已服务超过300以上的个人及企业客户',
        detailList: [
          {
            title: '投资组合多样性和风险管理',
            text: '信托公司需要管理多样化的投资组合，包括股票、债券、数字资产等，同时有效地管理客户侧可能带来的各类风险；全球金融市场的不确定性和波动性也可能影响投资决策，需要及时的市场信息和洞察力'
          },
          {
            title: '严苛的准入与投资监控',
            text: '系统具备强大的风险管理功能，在客户准入时做了严苛的KYC与AML流程管控，并在投资前做了深度的风险测评，同时我们会实时监控投资组合，引入第三方智能投资策略和量化分析，以优化投资决策和风险控制'
          },
          {
            title: '合规性和监管挑战',
            text: '离岸信托公司需要遵守不同国家和地区的金融监管法规和合规要求，这些法规通常相当复杂和变化多端'
          },
          {
            title: '合规第一的逻辑机制',
            text: '系统依据各类合规的监管要求做了针对的限制，所有的业务流程和操作第一时间都会考虑是否合规，同时对于变化的政策系统也会实时调整相关功能。'
          },
          {
            title: '安全隐私及客户信任',
            text: '信托公司处理大量敏感客户数据，需要确保数据的安全性和隐私保护，以防止数据泄露和恶意攻击，信托公司需要建立客户信任，也需要确保高等阶的安全隐私机制，并提供透明的投资信息和绩效报告'
          },
          {
            title: '高防御机制与数据透明',
            text: '系统采用了高级的数据加密技术和多层安全措施及灾备措施，确保数据的机密性和完整性。并为客户提供可验证的投资记录和透明的交易历史，完整的自助服务和知名的第三方API可以让用户具备更强的信任感'
          },
        ]
      },
      {
        title: '某大宗贸易公司',
        titleDesc: 'BTS·易货贸易系统',
        info: '中国向贸易国输出先进技术、设备及基建工程、轻工业及日用品等，优化中国产能结构，带动贸易国产业升级、提升民生供给及生活水平、维持政局稳定，贸易国官方推广以矿产、农产等资源类产品与中国易货，实现中国与贸易国双方合作共赢。通过商机发布全球化、业务管理智慧化、合规监管实时化、综合服务一体化的本易货贸易数字化管理服务平台，实现信息共享、协同监管，确保贸易真实性，保障各国贸易拓新发展，维护各国正常贸易秩序和外汇金融安全。',
        detailList: [
          {
            title: '复杂的业务流程',
            text: '中外易货业务涉及多个国家和地区的合同、物流、支付和报关等复杂流程，手工操作极易出错'
          },
          {
            title: '标准SOP业务流',
            text: '系统自带供应链的各类功能，以易货为主，支持自动化合同生成、各类业务流程的提单、审批和数据跟踪，可显著提高流程可见性，所有业务可细化到订单层级，并支持全程溯源'
          },
          {
            title: '货币和汇率风险',
            text: '易货业务涉及多种货币的交易，可能会导致货币风险，尤其是在汇率波动大的情况下，大宗交易也特别容易受汇率波动的影响，需要锁汇与保价'
          },
          {
            title: '解决汇率风险',
            text: '系统引入了外汇风险管理工具，如外汇合同和期货合同，以对冲货币风险。同时，实施实时汇率监控和汇率预测分析，以及财务规划工具，以降低汇率风险影响，对于波动较大的大宗期货，支持双方协商做保价与锁汇逻辑'
          },
          {
            title: '海关合规和报关自动化',
            text: '国际贸易中的海关合规和报关流程复杂，涉及税务、关税、进口限制等问题，容易导致延误和罚款，手工操作过于繁杂容易出错'
          },
          {
            title: '确保通关无忧',
            text: '系统采用电子海关报关接口，支持自动化报关流程和文件提交。并具备海关合规检查功能，确保了进出口的合规性。系统不仅支持0110的一般贸易，也支持0130的易货贸易，双模式并行确保通关无忧'
          },
        ]
      },
    ],
    offeringsList: [
      {
        title: '进销存产ERP*进出口供应链系统',
        titleDesc: '适用于代理、清关、生产、贸易、研发等供应链企业',
        tbs: ['进销存产ERP系统', '进出口供应链系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，从根本上解决企业经营问题',
        pointList: [
          {
            title: '盲目生产',
            text: '物料交付管理不到位。物料没来就只能先做已来料的产品。而交期快到了的产品，物料还未备齐，影响产品如期交货，只能花费高昂的物流成本满足客户交付。而仓库中还满是未到出货期的产品，增加仓库管理难度。'
          },
          {
            title: '库存不清',
            text: '仓库堆积如山的物料，不知道是什么料，有多少料；物料的叫法各异，明明还有很多物料，但是查不出来，导致重采购，库存积压。没人能准确的知道库存，采购人员也无法及时的了解到仓库的准确信息，无法将库存及时的扣除。'
          },
          {
            title: '协作较差',
            text: '手工作业的随意性太大，工作职责不清楚，企业管理人员忙于各部门监督工作，但找不到问题的根源，不同部门之间缺乏有效的沟通和协作机制，导致采购、生产和销售之间的冲突，增加运营成本，最终影响公司的效益和客户满意度。'
          }
        ],
        prodTitleDesc: '应用各类前沿技术，结合实际业务场景打造并创新产品',
        leftList: [
          {
            title: '业务建模',
            text: '根据10多年的进销存业务实践，沉淀形成供方准入模型，实现采购业务智能化。'
          },
          {
            title: '灵活配置',
            text: '通过配置定制企业专属的供应商生命周期管理流程，支持其持续优化供应商资质。'
          },
          {
            title: '业务支撑能力',
            text: '我们提供的产品场景化方案能力，契合企业经营中产采销业务多方面，更好支撑业务的发展。'
          },
          {
            title: '技术应用能力',
            text: '我们提供的产品适配最前沿技术，持续迭代升级，并具备成熟的各类对接接口。'
          }
        ],
        capabilityTitleDesc: '模块齐全、操作简单，配置灵活，满足多种业务场景',
        capabilityList: [
          {
            title: '客户关系管理',
            text: '客户关系管理标准化，有商机池、商机领用、商机跟进、销售漏斗、客户分配、客户跟进等客户全生命周期的管理，提高客户管理效率，轻松挖掘客户需求。'
          },
          {
            title: '供应链管理',
            text: '对供应链环节中繁杂的物料、销售、采购、库存四大板块提供规范管理。解决物料信息的不规范，把控采购供应商的准入，跟踪销售发货流程，精细化库存管理，将供应链多环节多操作变得准确有序，提高企业协同效率。'
          },
          {
            title: '生产制造管理',
            text: '准确高效的MRP运算框架，既解决生产物料之需又不产生浪费；缺料运算，实现按需生产、按需送货。'
          },
          {
            title: '行政办公管理',
            text: '通过信息化手段固化重要会议全流程周期管理，根据会议类型会议组织做好会议计划、议题管理、会议室申请、会议纪要等，支持与公文管理、日程管理、督办管理等功能贯通实现文事会一体化。'
          },
          {
            title: '人力资源管理',
            text: '提供人力总量、结构、流动、成本等关键指标分析提供人力关键指标的层层穿透服务，可透视到微观个体提供HR对标管理服务，可适时校正人才战略航向提供组织、日期等多维度的人力分析服务。'
          },
          {
            title: '财务会计管理',
            text: '多核算体系、多账簿、多准则为基础，融合智能RPA技术，为企业提供包括总账、往来、资产、存货在内的精细化财务核算和管理核算，实现企业财务核算的数智化。'
          },
          {
            title: '成本会计管理',
            text: '以企业内部管控为核心，通过数据赋能管理，帮助企业应对各种突发状况，优化业务决策并提升绩效。主要包括成本核算与管控、责任会计和获利分析三大体系。       '
          },
          {
            title: '公司报表管理',
            text: 'BI报表多维度数据分析，清晰地展示企业的经营状况，充分了解企业在经营阶段的盈亏情况，便于公司及时调整经营策略。'
          },
          {
            title: '系统维护管理',
            text: '已实现全电发票开具，实现开票全线上化，开票全程物质化，操作，提高了开票效率。预开票、应开票多种开票方式，满足客户不同的开票要求。'
          },
          {
            title: '常用功能管理',
            text: '系统可以设置各业务的常用功能，操作使用一目了然，无学习成本，新人打开系统就会使用。  '
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，轻轻松松经营企业',
        imgList: [
          {
            text: '采购',
            width: '33.33'
          },
          {
            text: '销售',
            width: '33.33'
          },
          {
            text: '仓储',
            width: '33.33',
            noRight: true
          },
          {
            text: '生产',
            width: '25'
          },
          {
            text: '财务',
            width: '25'
          },
          {
            text: '招聘',
            width: '25'
          },
          {
            text: '办公',
            width: '25',
            noRight: true
          }
        ]
      },
      {
        title: '进销存产ERP*进出口供应链系统',
        titleDesc: '适用于代理、清关、生产、贸易、研发等供应链企业',
        tbs: ['进销存产ERP系统', '进出口供应链系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，从根本上解决企业经营问题',
        pointList: [
          {
            title: '业务不透明',
            text: '很多企业会使用第三方设计的信息系统，这样的系统无法对接企业其他应用，不能实现统一登录和数据多点同步应用，存在数据孤岛现象。'
          },
          {
            title: '工作效率低下',
            text: '传统外贸进出口以人工线下纸质化处理模式，效率低下，耗费大量人力物力，业务不能高效的运转，不再适应当前的产业发展。'
          },
          {
            title: '劳动力短缺/人力成本高',
            text: '随着劳动力成本的不断升高，诸多企业面临着同样的用工难题，导致企业招人难，业务培养人才难，导致企业经营，面临很大的压力。'
          }
        ],
        prodTitleDesc: '引用人工智能(AI)、大数据、区块链等前沿技术，结合实际业务场景打造并创新产品',
        leftList: [
          {
            title: 'Al一键下单',
            text: 'OCR智能识别多种文件内容，一键生成进出口订单'
          },
          {
            title: '进度实时反馈',
            text: '订单各种状态，微信消息自动推送，轻松掌握'
          },
          {
            title: '单据随时下载',
            text: '报关单、缴款书、放行通知等单据，随时下载'
          },
          {
            title: '付结汇30分钟',
            text: '与银行接口打通实现银企直连，快速付结汇'
          },
          {
            title: '货物全程跟踪',
            text: '货物全流程可视化轨迹跟踪，安全又放心'
          }
        ],
        capabilityTitleDesc: '智能简单、自动化的产品功能，用户一看就会，无学习成本',
        capabilityList: [
          {
            title: '订单计划',
            text: '融合AI识别功能，支持将EXCEL/PDF等多种文件格式，实现一键转换成进出口订单功能，并通过对接E签宝，实现签署订单委托盖章，颠覆传统线下单。'
          },
          {
            title: '物流管理',
            text: '已接入 快递100、国际快递，GPS等，实现进出口货物全流程跟踪，物流状态更新，微信消息即时推送，轻轻松松掌握货物运输状态，让您不再烦恼。'
          },
          {
            title: '关务管理',
            text: '物料数据自动归类，与海关单一窗口接口打通，报关单自动发送，一个系统搞定全部操作，智能又简单。'
          },
          {
            title: '结算管理',
            text: '根据客户需求，设定单结、月结、半月结设定结算方式，满足客户不同的客户个性化结算需求，到结算日自动生成对账单，无需手动操作，非常的便捷方便。'
          },
          {
            title: '金融管理',
            text: '进口税款、退税款，货款企业授信额度，通过授信管理策略集中管控并自动授信额度。'
          },
          {
            title: '风险管理',
            text: '系统建立多种风险管控策略，轻松解决实际在生产业务中的各种难题。消灭或减少风险事件发生的各种可能性，或减少风险事件发生时造成的损失。'
          },
          {
            title: '客户关系',
            text: '客户线索、商机、立项、签约、存量全部线上管理、精准掌握客户情况多维度对客户进行数据分析，主动与客户建立起紧密联系，为企业达到经营目标。'
          },
          {
            title: '系统配置',
            text: '对员工进行权限控制和管理，使公司能够有效和高效的运转，能够保证员工各司其职，每个人所负责的内容不一样，互不干扰，从而提升工作效率。'
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，轻轻松松经营企业',
        imgList: [
          {
            text: '提货收货',
            width: '25'
          },
          {
            text: '代验货物',
            width: '25'
          },
          {
            text: '付汇结汇',
            width: '25'
          },
          {
            text: '报关报检',
            width: '25',
            noRight: true
          },
          {
            text: '跨境物流',
            width: '33.33'
          },
          {
            text: '国内外派送',
            width: '33.33'
          },
          {
            text: '垫税退税',
            width: '33.33',
            noRight: true
          }
        ]
      },
      {
        title: '国际物流*精益仓储系统',
        titleDesc: '从客户需求出发，直击痛点，解决企业经营问题',
        tbs: ['仓储WMS系统', '物流TMS系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，从根本上解决企业经营问题',
        pointList: [
          {
            title: '仓库规划不清晰',
            text: '仓库布局规划不太合理，区域划分不明确，造成空间利用困难和浪费。仓库管理系统不完善，没有明确的仓库运作流程，仓储作业规范及管理制度不健全。'
          },
          {
            title: '库存管理混乱',
            text: '物料摆放不合理，出现混、乱、杂 ，同种物料放置于几个地方，找货难，没有实施“先进先出”管理。物料入库随意，只要是供应商到货毫无条件的接收。'
          },
          {
            title: '仓库作业效率低',
            text: '仓库物料收发缺乏重要依据，工作盲目性严重，作业效率低，部门岗位职能、职责不清，分工不明确，责任不能落实到人导致浪费大量的人力物力以及精力。'
          }
        ],
        prodTitleDesc: '集成物联网技术、大数据能力及仓库预警机制，实现仓库精益化管理',
        leftList: [
          {
            title: '支持多仓业务类型',
            text: '支持保税仓、海外仓、国内仓等多种仓库作业场景'
          },
          {
            title: '支持货物批次精准跟踪',
            text: '对货物生产批次、生产日期、产地等批次属性管控'
          },
          {
            title: '支持标签全面应用',
            text: '作业流程应用PDA进行标签识别减少出错提高效率'
          },
          {
            title: '物联网技术深度集成',
            text: '结合物联网技术对仓库进行全面监控，实时掌握动态'
          },
          {
            title: '大数据报表及预警管理',
            text: '出入库效率综合分析、库存、呆滞料实时预警通知'
          }
        ],
        capabilityTitleDesc: '功能齐全、配置灵活、节点可跟踪、线上线下相结合',
        capabilityList: [
          {
            title: '入库管理',
            text: '支持PDA、PC端进行质检、收货、上架操作，系统智能分配上架库位，全程操作无纸质化，无需思考，入库效率极高，真正做到傻瓜式操作，无需学习成本，可以大大提高工作效率。'
          },
          {
            title: '出库管理',
            text: '支持PDA、PC端进行拣货、打包、复核操作，系统根据先进先出或批次属性规则自动分配库存，并以最优路线分配库存所在库位，可以用最快速、最短时间完成拣货出库。'
          },
          {
            title: '仓库管理',
            text: '仓库、库区、库位基础信息，统一进行规范管理，随时随地可以，对仓库相关信息进行维护，非常的便捷方便，并可以进行Excel批量导入，库位编码规则自动生成以及一键打印等。'
          },
          {
            title: '库存管理',
            text: '对货物的库存状态、批次属性等信息进行精准管控，做到库存变化过程可追溯，各种库不足、呆滞料、异常料预计分析以及消息提醒，实时更新库存动态。'
          },
          {
            title: '盘点管理',
            text: '全盘、动态盘多种盘点模式，根据不同盘点需求选择对应的盘点模式并进行灵活运用，能够大大地提升仓库管理效率，能够大大降低企业的成本。'
          },
          {
            title: '物料管理',
            text: '物料支持一键导入，并可以对物料单独设定周转规则、以及预警规则，能够满足单一物料个性化的出入库过程以及库存管理需求，非常灵活好用。'
          },
          {
            title: '业务规则',
            text: '以货主、品牌、物料维度，设置出入库过程周转规则、批次属性、上架策略、分配策略，灵活可配配置，满足不同行业不同场景下的仓储业务需求。'
          },
          {
            title: '仓库配置',
            text: '打印设置、扫码设置、拣货车可以自行设置新增与设置、灵活可配置打印模板、可以实现多种模板随时使用，可以满足不同的业务需求，功能非常的强大。'
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，轻轻松松经营企业',
        imgList: [
          {
            text: '收货',
            width: '25'
          },
          {
            text: '质检',
            width: '25'
          },
          {
            text: '上架',
            width: '25'
          },
          {
            text: '盘点',
            width: '25',
            noRight: true
          },
          {
            text: '拣货',
            width: '25'
          },
          {
            text: '打包',
            width: '25'
          },
          {
            text: '复核',
            width: '25'
          },
          {
            text: '发运',
            width: '25',
            noRight: true
          }
        ]
      },
      {
        title: '国际物流*精益仓储系统',
        titleDesc: '从客户需求出发，直击痛点，解决企业经营问题',
        tbs: ['仓储WMS系统', '物流TMS系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，从根本上解决企业经营问题',
        pointList: [
          {
            title: '管理流程不顺畅',
            text: '运力资源零散、车队管理低效等，这种粗放式的经营模式，致使物流业运作效率低、成本高、利润少、运力散、服务质量差、发展速度缓慢。'
          },
          {
            title: '货物跟踪困难',
            text: '传统物流公司查货时效性差，成本高效率低，查询货物耗时长。传统物流无法实时获取车辆运输实时位置，只能通过打电话的方式去联系司机，导致无法跟踪货物。'
          },
          {
            title: '专线接货效率低',
            text: '不同区域的货物需要联系不同的物流专线接发货，货主发货时需要找不同运输专线，这期间会耗费人力、时间和沟通等成本，导致发货时长延长和发货不便捷。'
          }
        ],
        prodTitleDesc: '引用人工智能(AI)、大数据、区块链等前沿技术，结合实际业务场景打造并创新产品',
        leftList: [
          {
            title: '功能强大',
            text: '多场景一键切换，端到端全流程管理；财务业务一体化，无需切换系统即可无缝衔接操作'
          },
          {
            title: '技术先进',
            text: '微服务技术架构，功能权限按需配置，支持按个人及团队分类设置权限，满足多样化需求'
          },
          {
            title: '安全可信',
            text: '多维度数据保护，安全可靠提供服务；额度及时预警，为业务安全运行保驾护航'
          },
          {
            title: '多维报表',
            text: '多种维度报表，为管理提供多方面的分析，及时发现问题和风险，把握好业务大盘'
          },
          {
            title: '智慧服务',
            text: '智慧洞察业务痛点，高效协同节约成本'
          }
        ],
        capabilityTitleDesc: '智能简单、自动化的产品功能，用户一看就会，无学习成本',
        capabilityList: [
          {
            title: '合作伙伴',
            text: '储存用户基本信息，方便企业做好定期客户管理和回访工作，有效跟进客户，提高客户满意度。对接天眼查自动甄别合作企业基本面信息和经营异常。'
          },
          {
            title: '运价维护',
            text: '价格表一键上传，同步客户端，客户自主查价，让您不错过任何一个订单。'
          },
          {
            title: '委托单管理',
            text: '客户端直接下达委托单，实现一单到底，免除重复工作。'
          },
          {
            title: '运单管理',
            text: '告别传统操作模式，全面抛弃纸制作业流转，让所有人在系统完成作业。操作日志可查，精准掌控客户和订单。'
          },
          {
            title: '仓储管理',
            text: '港前港后出入库管理，精确记录每一票货物出入库动态，有效追溯每一票，仓库错发、漏发率控制在0.01%，极大减少人为的出错。'
          },
          {
            title: '结算管理',
            text: '业财一体化，结算对象应收应付轻松管理，协同财务预警机制，精准掌控客户和财务风险'
          },
          {
            title: '财务管理',
            text: '关联工作单操作模块，对账，报表，凭证，发票自动化处理。'
          },
          {
            title: '基础资料',
            text: '供应商信息、客户信息、收发货地址、线上化管理，实现数据透明共享，减少了数据重复录入工作，提供了数据的精准度，减少数据错误的风险。'
          },
          {
            title: '报表统计',
            text: '接入第三方的BI报表分析，基于海运、空运、快递等各类业务，从货量、利润、货量利润、应收应付、载货清单、待办清单、运单等维度全方位分析数据，助力管理层快速决策。'
          },
          {
            title: '系统管理',
            text: '权限高度可配，支持员工个人信息个性化设置，让您的操作更称心。'
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，轻轻松松经营企业',
        imgList: [
          {
            text: '空运',
            width: '25'
          },
          {
            text: '海运',
            width: '25'
          },
          {
            text: '陆运',
            width: '25'
          },
          {
            text: '快递小包',
            width: '25',
            noRight: true
          }
        ]
      },
      {
        title: '电商*门店系统',
        titleDesc: '从客户需求出发，直击痛点，解决企业经营问题',
        tbs: ['电商系统', '门店系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，从根本上解决企业经营问题',
        pointList: [
          {
            title: '购物体验差',
            text: '客户的购物体验包括从网站的速度到设计、照片和内容的质量、查找信息的难易程度以及在商城中导航的方方面面。'
          },
          {
            title: '技术创新薄弱',
            text: '经过多年发展，我国电商结合本土需求，形成了独特业务模式创新的电商企业，但技术创新仍然薄弱。'
          },
          {
            title: '用户粘性不高',
            text: '用户粘性是电商企业发展的重要因素。但是，随着市场的竞争加剧和用户需求的多样化，电商企业面临用户粘性不高的问题。很多消费者在购买一次后就不再光顾同一个电商平台。'
          }
        ],
        prodTitleDesc: '用各类前沿技术，结合实际业务场景打造并创新产品',
        leftList: [
          {
            title: '百种营销方式随心用',
            text: '满减满送、x件y折、店铺优惠券、 免邮促销、平台推广活动等。'
          },
          {
            title: '多维的商业数据分析',
            text: '交易数据、业务数据、消费者数据全展示，为商家描绘客户画像提供依据。'
          },
          {
            title: '良好的交互使用体验',
            text: '美观易用的商城界面，购买流程流畅，提升用户体验及下单购买。'
          },
          {
            title: '注重移动端/PC端双重体验',
            text: '配套上线APP、小程序、等， 配合PC端打造无差异化购物体验。'
          }
        ],
        capabilityTitleDesc: '便捷产品管理、精细店铺管理、丰富后台管理功能',
        capabilityList: [
          {
            title: '会员管理',
            text: '管理电商平台会员信息、会员评论、会员积分、会员资金、会员充值、提现等功能，随时随地掌握会员情况。平台可设置客户成长值规则，客户获取成长值后提升等级，根据等级享受不同的权益'
          },
          {
            title: '订单管理',
            text: '用户下单后，系统会根据不同商家拆单，将对应订单分配给相应商家，商家对商品进行发货后台根据订单状态将订单分为待审核、待成团、待发货、待收货等，帮助商家提高订单管理效率。'
          },
          {
            title: '商品管理',
            text: '商品基础资料一键上传，批量导入，非常简单便捷方便，同时包含、商品审核、商品分类、品牌资料、规格参数、计量单位，基础信息批量录入，减少重复性的工作；'
          },
          {
            title: '促销管理',
            text: '商城系统含互动、营销等营销功能工具，团购、秒杀、拼团、促销活动，多种营销组合，全力为店铺拉新引流，帮助商家提升转化，提供企业销售业绩。'
          },
          {
            title: '店铺管理',
            text: '自营与招商入驻开店相结合，以线上交易与信息整合为核心，实现行业内多角色资源平台化运营。'
          },
          {
            title: '运营管理',
            text: '系统可以管理电商平台热词搜索、动态文章、用户反信息，可以便于了，活跃情况，实时掌握用户喜好以及购物动向。'
          },
          {
            title: '统计管理',
            text: '多方面了解商城经营数据，消费者行为数据，用于调整策略参考，更有实时交易数据监控，帮助你快速发现数据之间的联系及背后的价值，多方面分析， 让你轻松把握商业趋势，及时应对变化'
          },
          {
            title: '系统设置',
            text: '灵活设置组织架构，用户账号、功能权限、产品分类、客户类型等系统基础信息，减少基础信息的重复录入，能够大大减少员工的工作量；'
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，轻轻松松经营企业',
        imgList: [
          {
            text: '获客',
            width: '25'
          },
          {
            text: '购物',
            width: '25'
          },
          {
            text: '销售',
            width: '25'
          },
          {
            text: '发货',
            width: '25',
            noRight: true
          },
          {
            text: '收货',
            width: '33.33'
          },
          {
            text: '促销',
            width: '33.33'
          },
          {
            text: '客户服务',
            width: '33.33',
            noRight: true
          },
        ]
      },
      {
        title: '电商*门店系统',
        titleDesc: '从客户需求出发，直击痛点，解决企业经营问题',
        tbs: ['电商系统', '门店系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，从根本上解决企业经营问题',
        pointList: [
          {
            title: '竞争激烈拓客困难',
            text: '很多企业会使用第三方设计的信息系统，这样的系统无法对接企业其他应用，不能实现统一登录和数据多点同步应用，存在数据孤岛现象'
          },
          {
            title: '会员流失率高',
            text: '传统外贸进出口以人工线下纸质化处理模式，效率低下，耗费大量人力物力，业务不能高效的运转，不再适应当前的产业发展。'
          },
          {
            title: '加盟管理混乱',
            text: '随着劳动力成本的不断升高，诸多企业面临着同样的用工难题，导致企业招人难，业务培养人才难，导致企业经营，面临很大的压力。'
          }
        ],
        prodTitleDesc: '用各类前沿技术，结合实际业务场景打造并创新产品',
        leftList: [
          {
            title: '一体化',
            text: '将会籍、私教、采购、财务等部门业务集合在同一个平台上开展工作。'
          },
          {
            title: '快速部署',
            text: '免除软件安装实施过程中复杂的服务器部署环节'
          },
          {
            title: '功能模块',
            text: '按功能组划分模块，每个模块的每个按钮的权限都由管理员灵活掌握'
          },
          {
            title: '事件提醒',
            text: '让会籍、私教被动提醒、主动关心，提高续费率不再靠培训'
          }
        ],
        capabilityTitleDesc: '智能简单、自动化的产品功能，用户一看就会，无学习成本',
        capabilityList: [
          {
            title: '客户管理',
            text: '轻松统计会员数量以及会员卡、上课数据等，同时可标记会员、写备注信息，操作请假，停卡等。可以操作会员会员卡相关操作，如余额，有效期，续费，发卡等。直观简洁的大大提高管理效率。'
          },
          {
            title: '产品管理',
            text: '会员可在线查看场馆所售的各类会员卡，并可自主完成购买，同时馆长也可以线下收款后在会员管理中直接给会员发卡。'
          },
          {
            title: '课程管理',
            text: '业内最好的约课管理的设计。馆长或教练可以在这里直接的查看和管理每天的排课以及每个课的预约情况，同时支持代会员约课，代会员取消预约、修改上课时间，临时更改教练等。'
          },
          {
            title: '教务管理',
            text: '深度管理团课、私教课，支持批量排课，自动排课课后自动生成报表，自动统计每月教练上课，约课排行分析，每一条预约数据均可追溯。  '
          },
          {
            title: '订单管理',
            text: '客户一键小程序下单，后台订单履约全流程状态管理，实时掌握订单情况，并支持支付宝、微信多种支付方式付款，同时对售后退款退货能线上操作及管理'
          },
          {
            title: '加盟管理',
            text: '在线实现，门店加盟，对整个加盟过程进行全流监控及管理、并通且已实现，加盟商实现在线加盟签约、支付大大提高了加盟效率，降低了人员管理成本。'
          },
          {
            title: '内容管理',
            text: '一键发布新闻资讯、招聘信息、产品信息等内容，并实时同步到公司官网，同时支持BANNER，产品信息上传等功能，轻轻松松管理公司官网内容。'
          },
          {
            title: '系统配置',
            text: '灵活设置组织架构、用户账号、功能权限、产品分类、客户类型等系统基础信息，减少基础信息的重复录入，能够大大减少员工的工作量；'
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，轻轻松松经营企业',
        imgList: [
          {
            text: '获客',
            width: '25'
          },
          {
            text: '约课',
            width: '25'
          },
          {
            text: '排班',
            width: '25'
          },
          {
            text: '教学',
            width: '25',
            noRight: true
          },
          {
            text: '签到',
            width: '33.33'
          },
          {
            text: '加盟',
            width: '33.33'
          },
          {
            text: '购物',
            width: '33.33',
            noRight: true
          },
        ]
      },
      {
        title: '产业链金融*创新金融系统',
        titleDesc: '从客户需求出发，直击痛点，解决企业经营问题',
        tbs: ['创新金融系统', '产业链金融系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，进而针对性解决企业经营的各类问题',
        pointList: [
          {
            title: '合规要求繁杂',
            text: '离岸信托公司需要应对多个国际法域的法规和合规要求，包括反洗钱、反腐败、税务合规等。合规性的复杂性和不断变化的法律环境使得公司需要不断更新合规措施，以确保业务的合法性和稳健性'
          },
          {
            title: '安全要求高',
            text: '离岸信托公司存储和处理大量敏感客户信息，包括财务数据和个人身份信息。因此，数据泄露、网络攻击和隐私问题成为严重关切，需要强化数据安全措施，以防止潜在风险和法律责任'
          },
          {
            title: '投资多样化',
            text: '离岸信托公司管理多样化的投资组合，包括股票、债券、房地产和其他资产。有效管理不同资产类别的风险和回报，以满足客户的需求和目标，对公司而言是一项巨大挑战，需要高效的风险管理工具和策略'
          }
        ],
        prodTitleDesc: '通过平台将复杂的金融产品以简单的模式呈现给终端客户并提供自助及信托服务',
        leftList: [
          {
            title: '功能齐备',
            text: '连接了虚拟银行、信用卡、数字资产、证券投资、第三方支付、投资机构等各类丰富的API'
          },
          {
            title: '准入严格',
            text: '申请流程收集了各类完整资料，KYC审核具备严苛的评分评级，接入了AML审核和投资风险评测'
          },
          {
            title: '安全可控',
            text: '在严苛的强密码机制下加入了2FA、PIN码、人脸识别等机制，数据上多重备份，加密传输'
          },
          {
            title: '化繁为简',
            text: '通过各类流程与分析的设计，将复杂的金融操作、投资组合等流程简化，小白也可操作'
          }
        ],
        capabilityTitleDesc: '简单智能、准确安全、自动自助',
        capabilityList: [
          {
            title: '账户申请',
            text: '自官网注册到填写各类流程资料，通过KYC评分评级的审核，签署协议支付开户费完成申请，账户自带密码密保+2FA+PIN+人脸认证设定，满足金融级的安全设定'
          },
          {
            title: '虚拟银行',
            text: '接入世界顶级虚拟银行，符合法律法规，加入了SWIFT体系，可与全世界各类银行正常交易，可开设账户，在白名单范畴内进行转账、兑换、入金等操作并可随时查看交易记录及状态'
          },
          {
            title: '信用卡',
            text: '接入MasterCard和VISA体系，可通过虚拟银行充值使用，满足您在全世界任何国家消费的需求'
          },
          {
            title: '数字资产',
            text: '接入世界顶级数字资产服务商，符合法律法规，支持世界范围内的数字资产转入转出、买入卖出，交易所交易，与法币进行兑换等操作，可随时查看交易记录与状态'
          },
          {
            title: '证券投资',
            text: '接入了多家证券API，可通过证券平台连入交易所，查看行情，打通自己的交易账户，执行各类证券交易操作，查看实时的交易记录与交易状态，并可和银行资产联动'
          },
          {
            title: '信托组合',
            text: '可享受公司级信托集合服务，家族信托服务，签署信托协议后由专业的信托经理帮您打理财富'
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，让您有序运营',
        imgList: [
          {
            text: '国际信托',
            width: '25'
          },
          {
            text: '证券投资',
            width: '25'
          },
          {
            text: '虚拟银行',
            width: '25'
          },
          {
            text: '数字资产',
            width: '25',
            noRight: true
          },
          {
            text: '外汇兑换',
            width: '33.33'
          },
          {
            text: '信用卡',
            width: '33.33'
          },
          {
            text: '理财组合',
            width: '33.33',
            noRight: true
          },
        ]
      },
      {
        title: '产业链金融*创新金融系统',
        titleDesc: '从客户需求出发，直击痛点，解决企业经营问题',
        tbs: ['创新金融系统', '产业链金融系统'],
        pointTitleDesc: '从客户需求出发、了解业务痛点，进而针对性解决企业经营的各类问题',
        pointList: [
          {
            title: '融资难&贵',
            text: '当前的供应链金融体系中，金融产品的融资对象局限性较大，一般仅限于一级供应商，处于供应链远心端的中小型供应商（其中大多数为民营企业）仍难以借助核心企业的信用进行融资，融资难、融资贵的问题依然存在并且突出。'
          },
          {
            title: '找客户难',
            text: '供应链金融产品的单笔交易金额较小，授信主体背景复杂，囿于风控压力及政策要求，金融机构在开展此项业务的过程中，工作量大，边际成本高，且难以寻找到大量的优质客户及对应的资金出口。'
          },
          {
            title: '信息孤岛',
            text: '金融机构和企业间无统一的系统，导致信息无法传递，信任无法自证，缺乏相应的背书，无法让金融机构信服，而且结算结果没有通过金融机构，资金出现断层，增加金融机构对授信主体评估的困难。'
          }
        ],
        prodTitleDesc: '链接企业与金融机构，交易数据经过金融机构，授信简单省心',
        leftList: [
          {
            title: '打破信息孤岛',
            text: '企业交易数据自动同步到金融机构，为金融机构掌握授信主体的资金流向提供了数据源。'
          },
          {
            title: '自动授信',
            text: '基于真实交易数据，结合金融机构的风控模型，自动释放或回收授信额度，减少人工工作量。'
          },
          {
            title: '区块链',
            text: '区块链技术与信息化平台相结合，构建“区块链+产业链金融”的存证溯源式分布式生态。'
          },
          {
            title: '大数据分析',
            text: '基于企业的交易行为和交易流水，金融机构可对授信主体做多维度分析，全面剖析与监控客户。'
          }
        ],
        capabilityTitleDesc: '简单智能、准确安全、自动自助',
        capabilityList: [
          {
            title: '额度授信',
            text: '通过客户的多维度分析，提供工商信息、尽调资料与走访资料以及历史经营与财务数据，单据记录供金融机构评分评级，最终给予评估的授信额度'
          },
          {
            title: '融资审批',
            text: '通过订单推送，在通过内部风控审核后再将每一单的真实数据信息推送给金融机构审查，由金融机构执行审批操作，批准融资情况下的真实下单'
          },
          {
            title: '放款审批',
            text: '在单个或多个订单申请付款付汇时，呈现真实的待付款信息，在内部审核后交由金融机构审批，最终执行放款操作，整个放款可以采用银行托管的AB户划拨模式进行'
          },
          {
            title: '控货管理',
            text: '在需要发货执行回款时，在内部审核通过后，由金融机构管控货物的发出，必要情况下可由保证金介入'
          },
          {
            title: '回款管理',
            text: '所有的回款信息自动推送给金融机构，如有逾期情况马上通知金融机构，并呈现催收的状态和预期回款的情况，诉讼和强执等信息也通过接口同步，让每一笔回款有迹可循'
          },
          {
            title: '资料管理',
            text: '所有的票据、电子附件、纸质文档上传统一归口，采用区块链存证溯源，并统一推送给金融机构下载存档'
          }
        ],
        takeTitleDesc: '多场景覆盖，在一个系统管理所有业务，轻轻松松经营企业',
        imgList: [
          {
            text: '企业授信',
            width: '25'
          },
          {
            text: '订单融资',
            width: '25'
          },
          {
            text: '仓储融资',
            width: '25'
          },
          {
            text: '票据融资',
            width: '25',
            noRight: true
          },
          {
            text: '回款管理',
            width: '33.33'
          },
          {
            text: '存证溯源',
            width: '33.33'
          },
          {
            text: '电子档案',
            width: '33.33',
            noRight: true
          },
        ]
      },
    ]
  },
  getters: {
  },
  mutations: {
    userInfo (state, user) {
      state.userInfo = user
    },
    applyStatus (state, val){
      state.applyStatus = val
    },
    accountId (state, val){
      state.accountId = val
    },
    updateActiveMenu (state, name) {
      state.activeMenu = name
    },
    faceCollect (state, val) {
      state.faceCollect = val
    },
    faceVerify (state, val) {
      state.faceVerify = val
    },
    updateTabName(state, val) {
      state.tabName = val
    },
    updateCaseList(state, val) {
      state.caseList = val
    },
    updateOfferingsList(state, val) {
      state.offeringsList = val
    },
  },
  actions: {
  },
  modules: {
  }
})
