export default [
  {
    path: '/',
    component: () => import('../views/index.vue'),
    name: 'index',
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home.vue'),
      },
      {
        path: '/offerings',
        name: 'offerings',
        component: () => import('../views/offerings/index.vue')
      },
      {
        path: '/offerings-detail',
        name: 'offerings-detail',
        component: () => import('../views/offerings/detail.vue')
      },
      {
        path: '/case',
        name: 'case',
        component: () => import('../views/case/case.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue'),
      },
      {
        path: '/case-detail',
        name: 'case-detail',
        component: () => import('../views/case/case-detail.vue')
      }
    ]
  }
]