
import iconSvg from './icon-svg.vue'
import application from './application.vue'

const componentsLib = {
    components: [
        iconSvg,
        application
    ]
}

export default componentsLib