// @ts-nocheck
import { createApp } from 'vue'
import axios from 'axios'
import vueCookie from 'vue-cookies'
import { ElMessage } from 'element-plus'
import VueI18n from '../language'


const https = axios.create({
    timeout: 1000 * 300,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    // errorCode处理
    validateStatus: (status) => {
      return true
    }
})

/**
 * 请求拦截
 */
 https.interceptors.request.use((config: any) => {
   
    config.headers['lang'] = JSON.parse(window.localStorage.getItem('language'))

    if (config.method === 'get') {
      if (config.url.includes('?')) {
        config.url += '&_='
      } else {
        config.url += '?_='
      }
      config.url += new Date().getTime()
    }
    return config
  }, error => {
    return Promise.reject(error)
  })


  https.interceptors.response.use((response: any) => {
    //批量下载不报错
    if(response.request.responseType == 'arraybuffer') {
      return response
    }
    if (response.data.code !== 'C0000') {
      
      // ElMessage.error(VueI18n.global.t('error.500'))
      ElMessage.error(response.data.message)
  
    }
    return response
  }, error => {
    if (error.message.includes('timeout')) {
      ElMessage.error(VueI18n.global.t('error.408'))
    //   errorMessage('请求超时')
    } else {
      ElMessage.error(VueI18n.global.t('error.500'))
    //   errorMessage('请求错误')
    }
    return Promise.reject(error)
  })

  /**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
 export function adornUrl (actionName: any) {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  let baseUrl = process.env.VUE_APP_BASEURL
  actionName = process.env.VUE_APP_PREFIX + actionName
  return process.env.NODE_ENV !== 'production' ? '/proxyApi' + actionName : baseUrl + actionName
}

  /**
 * 封装get请求
 * @param url
 * @param data
 * @returns {Promise}
 */
 export function fetch (url:any, params = {}) {
  return new Promise((resolve, reject) => {
    https.get(adornUrl(url), {
      params: params
    })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

 export function post (url:any, data = {}, that:any, loading:any) {
  that ? that[loading] = true : ''
  return new Promise((resolve, reject) => {
    https.post(adornUrl(url), data)
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      }).finally(() => {
        setTimeout(() => {
          that ? that[loading] = false : ''
        }, 100)
      })
  })
}




export default https